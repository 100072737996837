import { ApiAreaOfFocus } from 'api/types/ApiAreaOfFocus';
import { ApiJobFunction } from 'api/types/ApiJobFunction';
import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { TileCarousel } from 'components/TileCarousel/TileCarousel';
import { useSearchAlgoliaRecommendedJobs } from 'modules/search/hooks/useSearchAlgoliaRecommendedJobs';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { useMainSelector } from 'store/hooks/useMainSelector';

type StandardCmsRecommendedJobsProps = {
  headline: string | null | undefined;
  layout: 'SLIM' | 'REGULAR';
  jobFunction: ApiJobFunction | null | undefined;
  areaOfFocus: ApiAreaOfFocus | null | undefined;
  locale?: UserLocale | 'all';
  source: 'RECOMMENDED_JOBS_CHECKBOX' | 'RECOMMENDED_JOBS_BLOCK';
};

export function CmsStandardRecommendedJobs({
  headline,
  layout,
  jobFunction,
  areaOfFocus,
  locale,
  source,
}: StandardCmsRecommendedJobsProps) {
  const searchLocation = useMainSelector(
    (state) => state.mainSearch.searchLocation,
  ) as SearchLocation;

  const { data: recommendedJobs, isLoading } = useSearchAlgoliaRecommendedJobs(
    searchLocation,
    {
      onlyWithLogo: layout === 'REGULAR',
      jobFunction,
      areaOfFocus,
      locale: locale === 'all' ? undefined : locale,
    },
  );

  const additionalAnalyticsProps: Record<string, string> = {};

  if (jobFunction) additionalAnalyticsProps.job_function = jobFunction;
  if (areaOfFocus) additionalAnalyticsProps.area_of_focus = areaOfFocus;

  if (isLoading) return <LoadingSection />;

  return (
    <TileCarousel
      headline={headline}
      results={recommendedJobs}
      seeAll={{
        /* TRANSLATORS: Link to job search */
        text: getText('See all jobs'),
        action: {
          type: 'link',
          url: searchGetJobsRoutePath({
            lang: CURRENT_LOCALE,
            filtersByName: {
              functions: jobFunction ? [jobFunction] : undefined,
              areasOfFocus: areaOfFocus ? [areaOfFocus] : undefined,
            },
          }),
        },
      }}
      listingType="JOB"
      carouselLocation="CMS Page"
      layout={layout}
      source={source}
      additionalAnalyticsProps={additionalAnalyticsProps}
    />
  );
}
